import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import PopupOpenerButton from '../PopupOpenerButton/PopupOpenerButton';

import css from './SearchFiltersPrimary.module.css';
import { createResourceLocatorString } from '../../../util/routes';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { parse } from '../../../util/urlHelpers';

const SearchFiltersPrimaryComponent = props => {
  const {
    rootClassName,
    className,
    children,
    isSecondaryFiltersOpen,
    toggleSecondaryFiltersOpen,
    selectedSecondaryFiltersCount, 
  } = props;
  const routeConfiguration = useRouteConfiguration();
  const history = useHistory();
 
  const classes = classNames(rootClassName || css.root, className);

  const toggleSecondaryFiltersOpenButton = toggleSecondaryFiltersOpen ? (
    <PopupOpenerButton
      isSelected={isSecondaryFiltersOpen || selectedSecondaryFiltersCount > 0}
      toggleOpen={() => {
        toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
      }}
    >
      <FormattedMessage
        id="SearchFiltersPrimary.moreFiltersButton"
        values={{ count: selectedSecondaryFiltersCount }}
      />
    </PopupOpenerButton>
  ) : null;

  return (
    <div className={classes}>
      <div className={css.filters}>
        {children}
        {/* <button
          type="button"
          onClick={() => {
            const keysToFilter = ['pub_PricingType'];
            const filteredObj = Object.keys(parse(history.location.search))
              .filter(key => !keysToFilter.includes(key))
              .reduce((acc, key) => {
                acc[key] = parse(history.location.search)[key];
                return acc;
              }, {});
            const params = {
                  ...filteredObj,
                  pub_PricingType: 'Daily',
                }
            
            history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, params));
          }}
        >
          Per Day
        </button>
        <button
          type="button"
          onClick={() => {
            const keysToFilter = ['pub_PricingType'];
            const filteredObj = Object.keys(parse(history.location.search))
              .filter(key => !keysToFilter.includes(key))
              .reduce((acc, key) => {
                acc[key] = parse(history.location.search)[key];
                return acc;
              }, {});
            const params = {
                  ...filteredObj,
                  pub_PricingType: 'Monthly',
                }
              
            history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, params));
          }}
        >
          Per Month
        </button> */}
        {toggleSecondaryFiltersOpenButton}
      </div>
    </div>
  );
};

SearchFiltersPrimaryComponent.defaultProps = {
  rootClassName: null,
  className: null,
  isSecondaryFiltersOpen: false,
  toggleSecondaryFiltersOpen: null,
  selectedSecondaryFiltersCount: 0,
};

SearchFiltersPrimaryComponent.propTypes = {
  rootClassName: string,
  className: string,
  isSecondaryFiltersOpen: bool,
  toggleSecondaryFiltersOpen: func,
  selectedSecondaryFiltersCount: number,
};

const SearchFiltersPrimary = SearchFiltersPrimaryComponent;

export default SearchFiltersPrimary;
